import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { AuthenticationService } from 'src/app/core/service/auth/authentication.service';
import { UsersService } from 'src/app/user/service/user.service';
import { HttpErrorResponse } from '@angular/common/http';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionModalComponent } from 'src/app/approvazioni/approvazioni-action/action.component';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

declare let $: any;

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html'
})
export class NavigationComponent implements OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  public showSearch = false;
  currentUser: any;
  urlLinkPhoto: string = "";
  name: string;
  email: string;
  employeeId: string;
  isDeleghe: boolean = false;
  delega: any;

  constructor(private readonly authenticationService: AuthenticationService,
    private readonly usersService: UsersService,
    private readonly modalService: NgbModal,
    private readonly toastr: ToastrService,
    private readonly router: Router
  ) { }

  ngOnInit() {

    this.employeeId = this.authenticationService.tokenJson.sub;
    this.usersService.find(this.employeeId).subscribe(
      {
        next:(data) => {
          this.currentUser = data.body;
          this.name = this.currentUser.display_name;
          this.email = this.currentUser.email;
          if (localStorage.getItem("role").includes("ROLE_APPROVER")) this.isDeleghe = true;
          this.urlLinkPhoto = this.currentUser.image_url;
        },
        error:(error: HttpErrorResponse) => {
          this.name = 'Nome Cognome';
          this.email = 'nome.cognome@gmail.com';
          this.urlLinkPhoto = 'assets/images/users/user.png';
        },
        complete:()=>{
          if(this.urlLinkPhoto == ""){
            this.urlLinkPhoto = 'assets/images/users/user.png';
          }
        }
      }
    );

    this.getDelega();
  }

  public signOutWithGoogle() {
    this.authenticationService.signOut();
    window.location.href = '/authentication/login';
  }

  openDeleghe() {
    const modalRef = this.modalService.open(ActionModalComponent, { size: 'lg' });
    modalRef.componentInstance.actionType = "DELEGA";
    modalRef.componentInstance.delega = this.delega;
    modalRef.result.then((result) => {
      if (result) {
        if (result.action == "delete") {
          this.usersService.deleteDelega(this.delega.id).subscribe(
            (data) => {
              this.delega = null;
              this.toastr.success('Delega eliminata con successo', 'Delega eliminata ');
              this.router.navigate(['/']);
            }
          );
        } else {
          const body: any = {
            applicant_email: this.email,
            delegated_email: result.email,
            start_date: result.dateFrom,
            end_date: result.dateTo,
            active: true
          }
          if (this.delega?.id) {
            this.usersService.updateDelega(body, this.delega.id).subscribe(
              (data) => {
                this.getDelega();
                this.toastr.success('Delega modificata con successo', 'Delega modificata ');
                this.router.navigate(['/'])
              }
            );
          } else {
            this.usersService.createDelega(body).subscribe(
              (data) => {
                this.getDelega();
                this.toastr.success('Delega creata con successo', 'Delega creata ');
                this.router.navigate(['/'])
              }
            );
          }
        }
      }
    });
  }

  getDelega() {
    this.usersService.getDelega().subscribe(
      {
        next:(data) => {
          if(data?.body) this.delega = data.body;
        },
        error:(error) => {
          console.log(error);
        }
      }
    );
  }
}
