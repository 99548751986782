
import { AreaResource, AreaType } from '../../area.resource';

export class AreajaggaerResource extends AreaResource {
   public static readonly TYPE =  AreaType.jaggaer;

   role: any;
   notes: string;
  
   constructor() {
    super(AreajaggaerResource.TYPE);
   }
}

export class UserOfAjaggaerTypeResource {
   id: string;
   value: string;
 }