import { BeneficiaryModel } from '../../model/beneficiary.model';

export class ExternalBeneficiaryModel extends BeneficiaryModel {
  company_belonging?: string;
  is_future_employee?: boolean;
  terminated_relationship_at?: any;
  id?: string;
  title_role_function?: string;
  company?: string;
  cdc?: string
}
