import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from './authentication.service';

/**
 * Verifica che l'utente non possa accedere ad altri sezione se non è loggato
 */
@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {

  constructor(
    private readonly router: Router,
    private readonly authService: AuthenticationService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (!this.authService.isAuthenticated()) {
      let pathname = window.location.pathname;
      localStorage.setItem('redirectTo', pathname);
      this.router.navigate(['/authentication/login']);
      return false;
    }
    return true;

  }

}
