export class BeneficiaryModel {

  public firstName: string;
  public lastName: string;
  public email: string;
  public matricola?: string;
  public type?: string;

  public toString() {
    return `BeneficiaryModel (${this.firstName}) (${this.lastName}) (${this.email})`;
  }
}
