export class CommentInfoResource{
  comments: CommentResource[];
}
export class CommentResource {
   id: string;
   user_id: string;
   create_time: string;
   message: string;
   task_id: string;
   type: string;
   process_instance_id: string;
}
