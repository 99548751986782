import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { ROUTES } from './menu-items';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService } from 'src/app/core/service/auth/authentication.service';
import { UsersService } from 'src/app/user/service/user.service';

declare let $: any;

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent implements OnInit {
  @Output() toggleSidebar = new EventEmitter<void>();

  toggleSide(liName) {
    if (!liName.currentTarget.classList.contains('has-arrow')) {
      this.toggleSidebar.emit();
    }
  }

  showMenu = '';
  showSubMenu = '';
  ruoloUser: string;
  ruoloApprover: boolean = true;
  roleSuperAdmin: boolean = false;
  roleAppAdmin: boolean = false;
  roleEngAdmin: boolean = false;
  ruoloAdmin: boolean = false;
  roleAdminBusinessTrip: boolean = false;
  isGestione: boolean = false;
  isViewerApplications: boolean = false;
  isViewerManagment: boolean = false;
  noShowSociety: boolean = false;

  public sidebarnavItems: any[];

  // this is for the open close
  addExpandClass(element: any) {
    if (element === this.showMenu) {
      this.showMenu = '0';
    } else {
      this.showMenu = element;
    }
  }
  addActiveClass(element: any) {
    if (element === this.showSubMenu) {
      this.showSubMenu = '0';
    } else {
      this.showSubMenu = element;
    }
  }

  constructor(
    public route: ActivatedRoute,
    private readonly authenticationService: AuthenticationService,
    private readonly usersService: UsersService
  ) {
    this.usersService.currentMessage.subscribe(message => this.ngOnInit());
  }

  ngOnInit() {
    this.initializeSidebarNavItems();
    this.initializeUserRoles();
    this.checkSociety();
  }

  private initializeSidebarNavItems(): void {
    this.sidebarnavItems = ROUTES.filter(sidebarnavItem => sidebarnavItem);
  }

  private initializeUserRoles(): void {
    this.ruoloUser = this.authenticationService.ruoloUser;

    this.ruoloApprover = !this.ruoloUser.includes('ROLE_APPROVER');
    this.ruoloAdmin = this.ruoloUser.includes('ROLE_ADMIN');
    this.roleSuperAdmin = this.ruoloUser.includes('ROLE_SUPER_ADMIN');
    this.roleAppAdmin = this.ruoloUser.includes('ROLE_ADMIN_APPLICATIONS');
    this.roleEngAdmin = this.ruoloUser.includes('ROLE_ADMIN_ENGAGEMENT');
    this.roleAdminBusinessTrip = this.ruoloUser.includes('ROLE_ADMIN_BUSINESS_TRIP');
    this.isGestione = this.hasAnyRole(['ROLE_HRBP', 'ROLE_DIRECTOR', 'ROLE_PAYROLL']);
    this.isViewerApplications = this.ruoloUser.includes('VIEWER_APPLICATIONS');
    this.isViewerManagment = this.ruoloUser.includes('VIEWER_ENGAGEMENT');
  }

  private hasAnyRole(roles: string[]): boolean {
    return roles.some(role => this.ruoloUser.includes(role));
  }

  private checkSociety(): void {
    const society = localStorage.getItem("society");
    if (society && society !== "undefined") {
      const parsedSociety = JSON.parse(society);
      this.noShowSociety = parsedSociety && !['1', '36', '45'].includes(parsedSociety);
    }
  }

}
