import { map } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpResponse , HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Serializer } from './serializer';
import { createRequestOption } from '../utils/request-util';
import { Page} from '../http/page.model';
import { PageInfoSerializer } from './page-info.serializer';
import { LinkSerializer } from './link.serializer';
import { Link } from './link.resource';
import { PageInfo } from './pageinfo.resource';

export abstract class ResourceService<T> {

 
  public pageInfoSerializer: PageInfoSerializer = new PageInfoSerializer();
  public linkSerializer: LinkSerializer = new LinkSerializer() ;

  // Http Headers
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic dGVzdDp0ZXN0',
      Origin: 'Siram - ripu'
    }),
  };


  constructor(
    protected httpClient: HttpClient,
    protected baseUrl: string,
    protected uri: string,
    protected serializer: Serializer
  ) {
  }

  public create(item: T): Observable<HttpResponse<T>> {
    return this.httpClient
      .post<T>(`${this.baseUrl}/${this.uri}`, item, { observe: 'response' })
      .pipe(
        map((res: HttpResponse<T>) => this.convertResponse(res))
      );
  }

  public update(id: any, item: T): Observable<HttpResponse<T>> {
    const copy = this.serializer.toJson(item);
    return this.httpClient
      .put<T>(`${this.baseUrl}/${this.uri}/${id}`, copy, {
        observe: 'response',
      }).pipe(
        map((res: HttpResponse<T>) => this.convertResponse(res))
      );
  }

  find(id: any): Observable<HttpResponse<T>> {
    return this.httpClient
      .get(`${this.baseUrl}/${this.uri}/${id}`, {
        observe: 'response',
      }).pipe(map((res: HttpResponse<T>) => this.convertResponse(res)));
  }

  query(req?: any): Observable<HttpResponse<Page<T>>> {
    const options = createRequestOption(req);
    return this.httpClient
      .get(`${this.baseUrl}/${this.uri}`, {
        params: options,
        observe: 'response',
      }).pipe(map((data: HttpResponse<Page<T>>) => this.convertArrayResponse(data)));
  }

  listAll(): Observable<HttpResponse<Page<T>>> {
    return this.httpClient
      .get(`${this.baseUrl}/${this.uri}`).pipe(map((data: HttpResponse<Page<T>>) => this.convertArrayResponse(data)));
  }

  delete(id: number) {
    return this.httpClient.delete(`${this.baseUrl}/${this.uri}/${id}`);
  }

  queryPaginated(baseUrl: string, urlOrFilter?: string | object): Observable<HttpResponse<Page<T>>> {
    let params = new HttpParams();
    let url = baseUrl;

    if (typeof urlOrFilter === 'string') {
      // we were given a page URL, use it
      url = urlOrFilter;
    } else if (typeof urlOrFilter === 'object') {
      // we were given filtering criteria, build the query string
      for (const key of Object.keys(urlOrFilter).sort((a, b) => a.localeCompare(b))) {
        const value = urlOrFilter[key];
        if (value != null) {
          params = params.set(key, value.toString());
        }
      }
    }

    return this.httpClient.get<HttpResponse<Page<T>>>(url, {
      params: params
    });
}

  protected convertResponse(res: HttpResponse<T>): HttpResponse<T> {
    const body: T = this.convertData(res.body);
    return res.clone({ body });
  }
  
  protected convertHiringResponse(res: HttpResponse<any>): HttpResponse<any> {
    const body = this.convertData(res.body);
    return res.clone({ body });
  }

  protected convertArrayResponse(res: HttpResponse<Page<T>>): HttpResponse<Page<T>> {
    const links: Link[] = this.convertLinks(res.body.links);
    const pageInfo: PageInfo = this.convertPageInfo(res.body.page);

    const body: Page<T> = res.body;
    const jsonResponse: T[] = res.body.content;
    const content: T[] = [];
    for(let d of jsonResponse){
      content.push(this.convertData(d));
    }
    body.links = links;
    body.page = pageInfo;
    body.content = content;

    return res.clone({ body });
  }

  protected convertData(data: any): T {
    return this.serializer.fromJson(data) as T;
  }
  private convertLinks(data: any): Link[] {
    const body: Link[] = [];
    for(let d of data){
      body.push(this.linkSerializer.fromJson(d));
    }
    return body;
  }

  private convertPageInfo(data: any): PageInfo {
    return this.pageInfoSerializer.fromJson(data);
  }

  findNextPage(link) {
    return link.rel === 'next';
}
}
