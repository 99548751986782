
import { AreaResource, AreaType } from '../../area.resource';

export class AreaProlabQResource extends AreaResource {
   public static readonly TYPE =  AreaType.prolabq;

   equivalent_profile_user: string;
   notes: string;
   type_profile : any;
   constructor() {
    super(AreaProlabQResource.TYPE);
   }
}


export class UserOfAProlabQTypeResource  {
   id: string;
   value: string;
 }
 


