import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse, HttpResponse} from '@angular/common/http';
import { AuthenticationService } from './authentication.service';
import { Observable } from 'rxjs/internal/Observable';
import { Router } from '@angular/router';
import { tap } from 'rxjs/operators';
import { AsyncSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { LoaderService } from 'src/app/services/loader.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(
    public auth: AuthenticationService, 
    private readonly loaderService : LoaderService, 
    private readonly router: Router, 
    private readonly toastr: ToastrService,
    private readonly authenticationService: AuthenticationService
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
   
    const subject = new AsyncSubject<HttpEvent<any>>();

    next.handle(request)
    .pipe(
      tap((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          subject.next(event);
          subject.complete();
        }
      }, (error: any) => {
        if (error instanceof HttpErrorResponse) {
          if(error.status === 404){
            this.loaderService.hide();
          }else if(error.status === 403){
            this.loaderService.hide();
            if(error.error.message.includes("Sessione scaduta")) {
              if(this.authenticationService.infoLocalExist()){
                this.showErrorMessage(error);
                this.authenticationService.signOut();
              }
              window.location.href = '/authentication/login';
            } else {
              this.router.navigate(['/authentication/403'])
            }
          } else{
            this.showErrorMessage(error);
          }
          const errorEvent = new HttpResponse({ });
          subject.next(errorEvent);
          subject.complete();
        }
      })
    ).subscribe();

   

    return subject;
  }

  showErrorMessage(error){
    this.loaderService.hide();
    if (error?.error?.subErrors != null){
      error.error.subErrors.forEach(singleError => {
        this.toastr.error(singleError.message , singleError.message, {
          timeOut: 10000
        });
      })
    } else if(error.url.includes("v1/request") || error.url.includes("send")) {
        this.toastr.error(error.error.message , "Errore nel salvataggio della richiesta", {
          timeOut: 10000
        });
    } else {
        this.toastr.error(error.error.message , "Errore", {
          timeOut: 10000
        });
    }
  }
}


