export class AreaTempozeroModel {
  // Definisco un construttore con valori di default vuoti in sostituzione del costruttore senza parametri
    public typeUser: any;
    public personal_approval_power: number;
    public commesse:any [];
    public cdc:any;
    public notes: string;
    public cavia: any;
    public cm_riferimento: any;
    public centrodicosto: string;
}
