import { Component, OnDestroy } from '@angular/core';
import { Router, NavigationStart, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { TranslateService} from "@ngx-translate/core";
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
})
export class AppComponent implements OnDestroy {
    public sub: any;

    constructor(
        //private readonly slimLoader: SlimLoadingBarService, 
        private readonly slimLoader: NgxSpinnerService,
        
        public router: Router , 
        public translate: TranslateService) {
        translate.setDefaultLang('it');
        translate.use('it');

        // Listen the navigation events to start or complete the slim bar loading
        this.sub = this.router.events.subscribe({
            next:(event) => {
                if (event instanceof NavigationStart) {
                    this.slimLoader.show();
                } else if (event instanceof NavigationEnd ||
                    event instanceof NavigationCancel ||
                    event instanceof NavigationError) {
                    this.slimLoader.hide();
                }
            },
            error:(error: any) => {
                this.slimLoader.hide();
            }
        });
    }

    ngOnDestroy(): any {
        this.sub.unsubscribe();
    }
}
