import { AreaResource, AreaType } from '../../area.resource';

export class AreaEnergyResource extends AreaResource {
   public static readonly TYPE =  AreaType.energy;

   profile_enabled: AssignmentResource;
   cdc_associated = [];
   hold: boolean;
   notes: string;
   enabled_disabled : boolean;

   constructor() {
    super(AreaEnergyResource.TYPE);
   }
}

 export class AssignmentResource{ 
  profile: ProfileResource;
  enabled_disabled: boolean;
  enable_disabled: boolean;
} 

export class ProfileResource{ 
  id: number;
  description: string;
} 

 export class CdcResource { 
   value: string;
   enable_disabled: boolean;
 }
