import { ProcessResource, ProcessInfoResource, HistoricProcessResource, ProcessInstanceResource } from './process.resource';
import { ProcessModel } from './process.model';
import { Injectable } from '@angular/core';
import { Serializer } from 'src/app/core/common/http/serializer';
import { RequestSerializer } from 'src/app/richieste/resource/request.serializer';
import { TaskSerializer } from './task.serializer';
import { TaskResource } from './task.resource';
import { SubmitterSerializer } from 'src/app/richieste/resource/submitter.serializer';

@Injectable({
  providedIn: 'root',
})
export class ProcessSerializer implements Serializer {

  constructor(
    private readonly _requestSerializer: RequestSerializer,
    private readonly _taskSerializer: TaskSerializer,
    private readonly _submitterSerializer: SubmitterSerializer
    ) {

  }
  fromJson(json: any): ProcessInfoResource {

    const resourceInfo = new ProcessInfoResource();
    let resource: ProcessResource;

    const jsonProcess = json.process;
    const type = jsonProcess.type;
    if (type === HistoricProcessResource.TYPE) {
      resource = new HistoricProcessResource();
      resource.total = jsonProcess.total;
      resource.progress = jsonProcess.progress;
    }  else {
      resource = new ProcessInstanceResource();
    }

    resource.id = jsonProcess.id;
    resource.status = jsonProcess.status;
    resource.startTime = jsonProcess.start_time;
    resource.endTime = jsonProcess.end_time;
    resource.request = this._requestSerializer.fromJsonRequest(jsonProcess.request);
    resource.message = jsonProcess.message;
    if(jsonProcess.action_date){
      resource.action_date = jsonProcess.action_date;
    }
    if(jsonProcess.action_submitter){
      resource.action_submitter = this._submitterSerializer.fromJson(jsonProcess.action_submitter);
    }

    resource.task = new Array<TaskResource>();
    for (const taskResource of jsonProcess.task) {
      resource.task.push(this._taskSerializer.fromJson(taskResource));
    }
    resourceInfo.process = resource;
    return resourceInfo;
  }

  toJson(model: ProcessModel): any  {
    return null;
  }
}
