import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';

import { ResourceService } from 'src/app/core/common/http/resource-service.service';
import { environment } from 'src/environments/environment';
import { UserSerializer } from '../resource/user.serializer';
import { UserResource } from '../resource/user.resource';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UsersService extends ResourceService<UserResource> {

  public messageSource = new BehaviorSubject('default message');
  currentMessage = this.messageSource.asObservable();

  changeMessage(message: any) {
    this.messageSource.next(message)
  }

  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      environment.backend.baseUrl,
      'employee',
      new UserSerializer()
    );
  }

  createDelega(body: any): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/hal+json');
    return this.httpClient
      .post<any>(`${this.baseUrl}/delegations/create`, body, { headers: headers , observe: 'response' });
  }

  updateDelega(body: any, id: number): Observable<HttpResponse<any>> {
    let headers = new HttpHeaders().set('Content-Type', 'application/hal+json');
    return this.httpClient
      .put<any>(`${this.baseUrl}/delegations/update/` + id, body, { headers:headers , observe: 'response' });
  }

  getDelega(): Observable<HttpResponse<any>> {
    return this.httpClient
      .get<any>(`${this.baseUrl}/delegations`, { observe: 'response' });
  }

  deleteDelega(index: number): Observable<HttpResponse<any>> {
    return this.httpClient
      .delete<any>(`${this.baseUrl}/delegations/` + index, { observe: 'response' });
  }
}
