import { AreaResource, AreaType } from '../../area.resource';
export class AreaFusionResource extends AreaResource {
   public static readonly TYPE =  AreaType.fusion;

   type_user: UserOfAfTypeResource;
   url: string;
   buyer: boolean;
   requester: boolean;
   approver: boolean;
   approval_power: number;
   approval_power_cost_centers: string;
   notes: string;

   constructor() {
    super(AreaFusionResource.TYPE);

  }
}

export class UserOfAfTypeResource {
  id: string;
  type: string;
}




