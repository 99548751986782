import { AreaResource, AreaType } from '../../area.resource';

export class AreaSalesforceResource extends AreaResource {
   public static readonly TYPE =  AreaType.salesforce;

   role_type: RoleTypeResource;
   udb_type_code: string;
   area_type_code: string;
   cdc_type_code: string;
   manager: ManagerResource;
   notes: string;

   constructor() {
    super(AreaSalesforceResource.TYPE);

}
}

export class RoleTypeResource  {
  id: number;
  type: number;
}

export class ManagerResource  {
  id: number;
  display_name: string;
}


