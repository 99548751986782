export class CommessaResource {
  udb_code: string;
  area_code: string;
  cdc_code: string;
  segment_code: string;
  project_name: string;
  project_description: string;
  active: boolean;
  cdc_type: string;
}
