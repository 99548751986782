import { AreaResource, AreaType } from '../../area.resource';

export class AreaBollalavoroResource extends AreaResource {
  public static readonly TYPE =  AreaType.bolla_lavoro;
  type_user: UserTypeResource;
  notes: string; 
  constructor() {
    super(AreaBollalavoroResource.TYPE);
  }
}

export class UserTypeResource{
  id: number;
  type: string;
}


