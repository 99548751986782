import { CommentResource } from './comment.model';

export class TaskResource {
  type: string;
  id: string;
  task_def_key: string;
  task_def_type: string;
  assignee: string;
  create_time: string;
  end_time: string;
  candidateGroup: string[];
  delegationState: string;
  status: string;
  comments: CommentResource[];
  constructor(type: string) {
    this.type = type;
  }
}
export enum TaskStatusResource {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
  TICKET_CREATED = 'ticket_created',
  TICKET_COMPLETED = 'ticket_completed',
  DENY = 'deny',
  CLOSED = 'closed',
  OPEN = 'open',
  PROCESSING = 'processing',
  DRAFT = 'draft',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  SEND = 'send',
  ANNULATED = 'annulated',
  TICKET_NUMBER_CREATED = 'ticket_number_created',
  NO_SHOW = 'no_show',
  TICKET_GENERATE_PROTOCOL_NUMBER = 'ticket_generate_protocol_number',
  CENSUS_CLOSE = 'Ricerca chiusa'
}
export enum TaskStatusResourceLabels {
  PENDING = 'in attesa',
  APPROVED = 'approvato',
  TICKET_CREATED = 'ticket creato',
  TICKET_COMPLETED = 'ticket completato',
  DENY = 'rifiutata',
  CLOSED = 'chiuso',
  OPEN = 'aperto',
  PROCESSING = 'in elaborazione',
  DRAFT = 'bozza',
  COMPLETED = 'completato',
  CANCELED = 'cancellata',
  ANNULATED = 'annullata',
  SEND = 'inviata',
  TICKET_NUMBER_CREATED = 'numero ticket creato',
  TICKET_GENERATE_PROTOCOL_NUMBER = 'numero protocollo generato',
  NO_SHOW = 'non presentato',
  CENSUS_CLOSE = 'Ricerca chiusa'
}

export enum CensusStatusResource {
  IN_MANAGE = 'in_manage',
  PROCESSING = 'processing',
  SUSPENDEND = 'suspendend',
  TO_MANAGE = 'to_manage',
  WAITING = 'waiting',
  COMPLETED = 'completed',
  TO_MANAGE_MESSAGE = 'to_manage_message',
  IN_COMPLETION = 'in_completion',
  IN_STREGHT = 'in_streght',
  NO_SHOW = 'no_show',
  CENSUS_CLOSE = 'census_close',
  PROPOSAL_CANCELED = 'proposal_canceled',
  PROPOSAL_CREATED = 'proposal_created',
  TO_FINALIZED = 'to_finalized',
}

export enum CensusStatusResourceLabels {
  IN_MANAGE = 'in gestione',
  PROCESSING = 'in elaborazione',
  SUSPENDEND = 'in sospeso',
  TO_MANAGE = 'da gestire',
  WAITING = 'in attesa',
  COMPLETED = 'completato',
  TO_MANAGE_MESSAGE = 'assegna a me',
  IN_COMPLETION = 'In ultimazione',
  IN_STREGHT = 'In forza',
  NO_SHOW = 'Non presentato',
  CENSUS_CLOSE = 'Ricerca chiusa',
  PROPOSAL_CANCELED = 'Proposta cancellata',
  PROPOSAL_CREATED = 'Proposta creata',
  TO_FINALIZED = 'Da finalizzare',

}

export enum RaasStatusResource {
  APPROVED = 'approved',
  DENY = 'deny',
  DRAFT = 'draft',
  PENDING = 'pending',
  CLOSE = 'close',
}

export enum RaasStatusResourceLabels {
  APPROVED = 'approvata',
  DENY = 'rifiutata',
  DRAFT = 'bozza',
  PENDING = 'In attesa di approvazione',
  CLOSE = 'Ricerca chiusa'
}

export class HistoricTaskResource extends TaskResource {
  public static readonly TYPE = 'historic';

  constructor() {
    super(HistoricTaskResource.TYPE);

  }
}

export class TaskInstanceResource extends TaskResource {
  public static readonly TYPE = 'present';

  constructor() {
    super(TaskInstanceResource.TYPE);

  }
}
