import { AreaResource, AreaType } from '../../area.resource';

export class AreaSupervisionResource extends AreaResource {
  public static readonly TYPE = AreaType.supervision_systems;
  system: SystemResource;
  notes: string;
  constructor() {
    super(AreaSupervisionResource.TYPE);
  }
}


export class SystemResource{
  id: number;
  description: string;
}
