// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#dtPluginExample.table.table-striped.table-hover.dt-responsive.nowrap td,
#dtPluginExample.stripe.hover.row-border.dt-responsive.nowrap td {
  font-size: 0.90rem !important;
}

.atr-remove-double-caret {
  -webkit-appearance: none;
  -moz-appearance: none;
}

button:disabled{
  cursor: auto;
}

#beneficiari-caption{
  display:none;
}`, "",{"version":3,"sources":["webpack://./src/app/richieste/applicant-data/find-internal-beneficiary/find-internal-beneficiary-table/find-internal-beneficiary.css"],"names":[],"mappings":";AACA;;EAEE,6BAA6B;AAC/B;;AAEA;EACE,wBAAwB;EACxB,qBAAqB;AACvB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;AACd","sourcesContent":["\n#dtPluginExample.table.table-striped.table-hover.dt-responsive.nowrap td,\n#dtPluginExample.stripe.hover.row-border.dt-responsive.nowrap td {\n  font-size: 0.90rem !important;\n}\n\n.atr-remove-double-caret {\n  -webkit-appearance: none;\n  -moz-appearance: none;\n}\n\nbutton:disabled{\n  cursor: auto;\n}\n\n#beneficiari-caption{\n  display:none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
