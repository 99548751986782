
export abstract class BeneficiaryResource {
   id: number;
   type: string;
   first_name: string;
   last_name: string;
   email: string;
   matricola : string;
   company_belonging ?: string;
   is_future_employee ?: boolean;
   cdc_code ?: string;
   society?: any;
   terminated_relationship_at ?: any;
  constructor(type: string) {
    this.type = type;
  }
}

export class BeneficiarySummaryResource extends BeneficiaryResource {
  image_url: string;
  display_name: string;
}
