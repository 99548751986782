// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fix {
  right: 0;
  position: fixed;
}`, "",{"version":3,"sources":["webpack://./src/app/layouts/full/full.component.scss"],"names":[],"mappings":"AAAA;EACI,QAAA;EACA,eAAA;AACJ","sourcesContent":[".fix{\n    right: 0;\n    position: fixed;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
