import { AreaResource, AreaType } from '../../../area.resource';

export class AreaFlooraResource extends AreaResource {
   public static readonly TYPE =  AreaType.floora;

   type_portal: TypePortalResource;
   type_user: UserFTypeResource;
   profile: string;
   notes: string;

   constructor() {
    super(AreaFlooraResource.TYPE);
   }
}

export class TypePortalResource{
  id: number;
  description: string;
}

export class UserFTypeResource{
  id: string;
  type: string;
}