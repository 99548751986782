import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { retry } from 'rxjs/operators';
import { ProcessRestService } from './process-rest.service';
import { PageQuery } from 'src/app/core/common/http/pageQuery.model';
import { PageInfo } from 'src/app/core/common/http/pageinfo.resource';
import { ApprovalRestService } from './approval-rest.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ActionType, ActionResource } from '../approvazioni-action/resource/action.resource';
import { AdditionalFields } from '../approvazioni-action/resource/additionalFields.resource';
import { ActionModalComponent } from '../approvazioni-action/action.component';
import { ApprovalActionResource } from '../approvazioni-action/resource/approval-action.resource';
import { ToastrService } from 'ngx-toastr';
import { AppUtilService } from 'src/app/core/common/service/app-util.service';

export class ApprovazioniFilterModel {
  // Filtri date
  TaskCreatedAfter: string;
  TaskCreatedBefore: string;
  requestSubmitter: string;
  requestBeneficiary: string;
  history: boolean;
  requestId: string;
}

@Injectable({
  providedIn: 'root',
})
export class ApprovalService {

  approvazioniFilter: ApprovazioniFilterModel;
  isDeny: string;
  content;

  constructor(
    private readonly modalService: NgbModal,
    private readonly _approvalRestService: ApprovalRestService,
    private readonly _processRestService: ProcessRestService,
    private readonly toastr: ToastrService,
    private readonly _appUtilService: AppUtilService
  ) {
  }

  loadListApproval(pageableSorting: PageQuery, callback?: any) {
    let approvazioniFilterNew;
    if (this.approvazioniFilter) {

      approvazioniFilterNew = this._appUtilService.convertToSnakeCaseAndClearMap(this.approvazioniFilter);

    }

    this.loadListApprovalFilter(pageableSorting, approvazioniFilterNew, callback);
  }


  public loadListApprovalFilter(pageableSorting: PageQuery, filterRequest: Map<string, string> = new Map(), callback?: any) {

    let filter = pageableSorting;
    if (filterRequest.size > 0) {


      const objFilter = this._appUtilService.convertMapToObj(filterRequest);

      filter = Object.assign(filter, objFilter);
    }

    this._approvalRestService.query(filter).pipe().subscribe({
      next: (response) => {

        this.content = response.body.content;
        const pageInfo: PageInfo = response.body.page;

        callback({
          recordsTotal: pageInfo.totalElements,  // Total records, before filtering (i.e. the total number of records in the database)
          recordsFiltered: pageInfo.totalElements, // Total records, after filtering (i.e. the total number of records after filtering has been applied - not just the number of records being returned for this page of data).
          data: this.content,
        });
      },
      error: (error: HttpErrorResponse) => {
        callback({
          error: error
        });
      }


    });
  }

  renderUser(user) {
    if (Array.isArray(user) && user.length) {
      user = user[0]
    }
    const firstName = user.first_name;
    const lastName = user.last_name;
    const displayName = firstName + '  ' + lastName;
    return `
         <div class="d-flex align-items-center">
                  <div class="m-r-10">
                     <a class="btn btn-circle btn-success text-white profile-no-effect">${firstName.charAt(0).toUpperCase() + '' + lastName.charAt(0).toUpperCase()}</a>
                  </div>
                  <div>
                     <h4 class="m-b-0 font-16">${displayName}</h4>
                  </div>
               </div>`;
  }

  openActionModal(actionType: ActionType, taskId: string,): NgbModalRef {
    const modalRef = this.modalService.open(ActionModalComponent, { size: 'lg' });

    // Passo l'action da seguire
    modalRef.componentInstance.actionType = actionType;

    // Passo la riga selezionata per l'approvazione
    modalRef.componentInstance.taskId = taskId;

    modalRef.componentInstance.user = 1;

    return modalRef;
  }



  approvalAction(processId: string, taskId: string, actionType: ActionType, comment: string, addFields: AdditionalFields, callback: any) {

    const approvalResource = new ApprovalActionResource();

    const actionResource = new ActionResource();
    actionResource.type = actionType;
    if (comment) {
      actionResource.comment = comment;
    }
    approvalResource.action = actionResource;


    if (addFields) {
      approvalResource.additional_fields = addFields;
    }


    this._processRestService.action(processId, taskId, approvalResource)
      .pipe(retry(1)).subscribe({
        next: (response) => {
          if (!response.url) {
            return;
          }
          if (response.status > 299 && response.status < 499) {
            console.log('errore', response)
            this.toastr.error('Si è verificato un errore nell\'approvazione della richiesta.', 'Approvazione fallita', {
              timeOut: 3000
            });
          } else {
            this.isDeny = actionType
            if (this.isDeny === "DENY") {
              this.toastr.success('Richiesta rifiutata con successo', 'Richiesta rifiutata', {
                timeOut: 3000
              });
            } else {
              this.toastr.success('Richiesta approvata con successo', 'Richiesta approvata', {
                timeOut: 3000
              });
            }
          }


          callback({
            response: response
          });
        },
        error: (error: HttpErrorResponse) => {
          this.toastr.error('Si è verificato un errore nell\'approvazione della richiesta.', 'Approvazione fallita', {
            timeOut: 3000
          });
          callback({
            error: error
          });
        }
      });
  }

  setApprovazioniFilterModel(filter: ApprovazioniFilterModel) {
    this.approvazioniFilter = filter;
  }
}
