import { AreaResource } from '../area/area.resource';
import { BeneficiaryResource } from '../applicant-data/resource/beneficiary.resource';
import { SubmitterResource } from './submitter.resource';

export class RequestInfoResource   {
  request: RequestResource;
}

export class RequestResource {
  id: number;
  process_id: string;
  type: string;
  created_at: string;
  modify_at: string;
  beneficiary: BeneficiaryResource[];
  area: AreaResource;
  submitter_id: number;
  submitter: SubmitterResource;
  status: string;
  ticket_id : string;
  spec_raas?: any;

  constructor(type: string) {
    this.type = type;
  }
}

export class RequestSummaryResource extends RequestResource  {
  static readonly TYPE = 'summary';

  constructor() {
    super(RequestSummaryResource.TYPE);
  }
}

export class RequestDetailResource extends RequestResource  {
  static readonly TYPE = 'detail';

  constructor() { 
    super(RequestDetailResource.TYPE);

  }
}
