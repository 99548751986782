export class UserResource{
  id: number;
  last_name: string;
  first_name: string;
  email: string;
  image_url: string;
  display_name: string;
  cdc: string;
  matricola : string;
  typology: string;
  society?: any;
  type?: string;
}