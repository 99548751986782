import { ActionResource } from './action.resource';
import { AdditionalFields } from './additionalFields.resource';

export class ApprovalActionResource {
  action: ActionResource;
  additional_fields: AdditionalFields;
}



