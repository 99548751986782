// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ngx .ng-valid, .ng-touched.ng-valid {
    border-color: #e9ecef;
}

.input-group-prepend {
    margin-right: 0px;
}
.dropdown-menu{
    border : 1px solid #bfbfbf;
}`, "",{"version":3,"sources":["webpack://./src/app/richieste/applicant-data/add-external-beneficiary/add-external-beneficiary.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,iBAAiB;AACrB;AACA;IACI,0BAA0B;AAC9B","sourcesContent":["#ngx .ng-valid, .ng-touched.ng-valid {\n    border-color: #e9ecef;\n}\n\n.input-group-prepend {\n    margin-right: 0px;\n}\n.dropdown-menu{\n    border : 1px solid #bfbfbf;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
