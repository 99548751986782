import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModule, LocationStrategy, PathLocationStrategy, CurrencyPipe, registerLocaleData} from '@angular/common';
import { NgModule, LOCALE_ID} from '@angular/core';
import localeIt from '@angular/common/locales/it';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { NgbModule,NgbActiveModal,NgbModalModule  } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from 'ng-agm-core-lib';
import { NgxSpinnerModule } from "ngx-spinner";
import { FullComponent } from './layouts/full/full.component';
import { BlankComponent } from './layouts/blank/blank.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NavigationComponent } from './shared/header-navigation/navigation.component';
import { SidebarComponent } from './shared/sidebar/sidebar.component';
import { BreadcrumbComponent } from './shared/breadcrumb/breadcrumb.component';
import { Approutes } from './app-routing.module';
import { AppComponent } from './app.component';
import { SpinnerComponent } from './shared/spinner/spinner.component';
import { LoaderInterceptor } from './interceptors/loader.interceptor';
import { ToastrModule } from 'ngx-toastr';
import { TokenInterceptor } from '../app/core/service/auth/token.interceptor';
import { JwtModule } from '@auth0/angular-jwt';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { DragulaModule } from 'ng2-dragula';
import { QuillModule } from 'ngx-quill';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { BlockUIModule } from 'ng-block-ui';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ErrorInterceptor } from './core/service/auth/error.interceptor';
import { ActionModalComponent } from './approvazioni/approvazioni-action/action.component';
import { ApplicationDataModule } from './richieste/applicant-data/applicant-data.module';
import { SendRaasConfirmComponent } from './assunzioni/resource/assunzioni.confirmsend.resource';
import { environment } from 'src/environments/environment';
import { RichiesteActionModule } from './richieste/richieste-action/richieste-action.module';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { DataTablesModule } from 'angular-datatables';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider } from '@abacritt/angularx-social-login';



// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function tokenGetter() {
  return localStorage.getItem('token');
}
registerLocaleData(localeIt);

@NgModule({
    declarations: [
        AppComponent,
        SpinnerComponent,
        FullComponent,
        BlankComponent,
        NavigationComponent,
        BreadcrumbComponent,
        SidebarComponent,
        ActionModalComponent,
        SendRaasConfirmComponent
    ],
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModalModule,
        SocialLoginModule,
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        QuillModule.forRoot(),
        DragulaModule.forRoot(),
        RouterModule.forRoot(Approutes, { useHash: false }),
        NgMultiSelectDropDownModule.forRoot(),
        AngularMultiSelectModule,
        BrowserModule,
        ApplicationDataModule,
        BrowserAnimationsModule,
        HttpClientModule,
        JwtModule.forRoot({
            config: {
                tokenGetter
            },
        }),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        BlockUIModule.forRoot(),
        NgbModule,
        NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
        RouterModule.forRoot(Approutes, { useHash: false, enableTracing: false }),
        AgmCoreModule.forRoot({ apiKey: environment.google.mapkey, language: 'it' }),
        ToastrModule.forRoot(),
        DataTablesModule.forRoot(),
        RichiesteActionModule
    ],
    providers: [
        CurrencyPipe,
        NgbActiveModal,
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
              autoLogin: false,
              providers: [
                {
                  id: GoogleLoginProvider.PROVIDER_ID,
                  provider: new GoogleLoginProvider(
                    environment.googleOauth.clientId,
                    {
                      oneTapEnabled: false, // default is true
                    }
                  )
                }
              ],
              onError: (error) => {
                console.error(error);
              }
            } as SocialAuthServiceConfig,
        },
        {
            provide: LOCALE_ID,
            useValue: "it"
        },
        {
            provide: LocationStrategy,
            useClass: PathLocationStrategy, // Provider per gli url applicativi ( HashLocationStrategy : aggiunge un # nell'url)
        },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true } // loader interceptor
    ],
    exports: [TranslateModule, RichiesteActionModule],
    bootstrap: [AppComponent]
})
export class AppModule {}
