import { AreaResource, AreaType } from '../../area.resource';
import { BelongingGroupResource } from './belonging_groups.resource';


export class AreaTimeSheetResource extends AreaResource {
  public static readonly TYPE = AreaType.timesheet;

  typology: UserTypologyResource;
  belonging_groups: BelongingGroupResource[];
  cdc: string;
  notes: string;

   constructor() {
    super(AreaTimeSheetResource.TYPE);

}}


export class UserTypologyResource {
  id: number;
  description: string;
}



