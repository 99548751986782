import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ResourceService } from 'src/app/core/common/http/resource-service.service';
import { environment } from 'src/environments/environment';
import { createRequestOption } from 'src/app/core/common/utils/request-util';
import { map, Observable} from 'rxjs';
import { Page } from 'src/app/core/common/http/page.model';
import { ApprovalResource } from '../resource/approval.resource';
import { ApprovalSerializer } from '../resource/approval.serializer';

@Injectable({
  providedIn: 'root',
})
export class ApprovalRestService extends ResourceService<ApprovalResource> {

  constructor(httpClient: HttpClient) {
    super(
      httpClient,
      environment.backend.baseUrl,
      'approvals',
      new ApprovalSerializer()
    );
  }

  history(req?: any): Observable<HttpResponse<Page<ApprovalResource>>> {
    const options = createRequestOption(req);
    return this.httpClient
      .get(`${this.baseUrl}/${this.uri}/history`, {
        params: options,
        observe: 'response',
      }).pipe(map((data: HttpResponse<Page<ApprovalResource>>) => this.convertArrayResponse(data)));
  }
}
