import { AreaResource, AreaType } from '../../area.resource';
import { FunctionsResource } from './functions.resource';

export class AreaEsightResource extends AreaResource {
  public static readonly TYPE = AreaType.esight;

  profile: ProfileResource;
  functions: FunctionsResource[];
  commesse: string;
  notes: string;

   constructor() {
    super(AreaEsightResource.TYPE);

}}


export class ProfileResource{
  id: number;
  description: string;
}