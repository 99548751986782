import { AreaResource, AreaType } from '../../area.resource';

export class AreaOracleFieldServiceCloudResource extends AreaResource {
  static readonly TYPE = AreaType.ofsc;
  type_user: UserOfscTypeResource;
  skill_of_type_100: SkillOfscType100Resource;
  skill_of_type_50: SkillOfscType50Resource;
  membership_group: string;
  working_area: string;
  notes: string;

  constructor() {
    super(AreaOracleFieldServiceCloudResource.TYPE);
  }
}

export class UserOfscTypeResource  {
  id: number;
  type: string;
}
export class SkillOfscType100Resource  {
  id: number;
  type: string;
}
export class SkillOfscType50Resource  {
  id: number;
  type: string;
}



