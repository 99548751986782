import { Component } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { Subject } from 'rxjs';

@Component({
	selector: 'app-spinner',
	templateUrl: './spinner.component.html',
	styleUrls: [ './spinner.component.scss' ]
})
export class SpinnerComponent {
	isLoading: Subject<boolean> = this.loaderService.isLoading;

	constructor(public loaderService: LoaderService) {}

}
