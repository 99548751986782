import { AreaResource, AreaType } from '../../area.resource';


export class AreaPitecoResource extends AreaResource {
   public static readonly TYPE =  AreaType.piteco;

   type_user: UserOpAfTypeResource;
   belonging_office: BelongingOfficeResource;
   notes: string;

   constructor() {
    super(AreaPitecoResource.TYPE);
   }
}

export class UserOpAfTypeResource  {
  id: number;
  type: string;
}
export class BelongingOfficeResource  {
  id: number;
  description: string;
}


