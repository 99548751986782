import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { NgbModalModule, NgbModule, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DragulaModule } from 'ng2-dragula';
import { QuillModule } from 'ngx-quill';

import { DataTablesModule } from 'angular-datatables';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { BlockUIModule } from 'ng-block-ui';
import { ToastrModule } from 'ngx-toastr';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient } from '@angular/common/http';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { SubmitterDetailComponent } from 'src/app/richieste/submitter-detail/submitter-detail.component';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NgbModalModule,
        ToastrModule.forRoot({
            maxOpened: 10,
            preventDuplicates: true,
            timeOut: 10000,
            positionClass: 'toast-bottom-right',
        }),
        CalendarModule.forRoot({
            provide: DateAdapter,
            useFactory: adapterFactory,
        }),
        BlockUIModule.forRoot(),
        QuillModule.forRoot(),
        DragulaModule.forRoot(),
        
        DataTablesModule,
        NgMultiSelectDropDownModule,
        AngularMultiSelectModule,
        TranslateModule,
        SharedModule
    ],
    declarations: [
        SubmitterDetailComponent
    ],
    providers: [
        NgbActiveModal,
        DatePipe
    ],
    exports: [
        SubmitterDetailComponent
    ]
})
export class SubmitterDetailModule { }
