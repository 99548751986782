// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#user-detail-table-caption{
    display:none;
}`, "",{"version":3,"sources":["webpack://./src/app/richieste/applicant-data/beneficiary-detail/beneficiary-detail.css"],"names":[],"mappings":"AAAA;IACI,YAAY;AAChB","sourcesContent":["#user-detail-table-caption{\n    display:none;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
